import { useMemo, useState, useCallback } from 'react';

// Components
import DynamicTabs from 'components/Tabs/DynamicTabs';
import ClientSearchViaAdvisor from 'components/ClientSearchViaAdvisor/ClientSearchViaAdvisor';
import ClientSearchViaId from 'components/ClientSearchViaAdvisor/ClientSearchViaId';
import PageHeading from 'components/PageHeading/PageHeading';

const TABS_NAMES = [
	{ type: 'Search via Advisor' },
	{ type: 'Pull via client id' }
];

function ClientsPage() {
	// Tab State
	const [tabPanelSelected, setTabPanelSelected] =
		useState(0);

	const handleTabSelection = useCallback((e, value) => {
		setTabPanelSelected(value);
	}, []);

	const tabPanelItems = useMemo(() => {
		return [
			{
				children: <ClientSearchViaAdvisor />
			},
			{
				children: <ClientSearchViaId />
			}
		];
	}, []);

	return (
		<>
			<PageHeading
				title="Clients"
				subtitleText="This tool allows you to view client data from their wallet overview"
			/>

			<DynamicTabs
				tabItems={TABS_NAMES}
				tabItemSX={{
					padding: 0,
					textTransform: 'none',
					marginRight: 5
				}}
				tabsSX={{
					marginBottom: 4
				}}
				tabPanelItems={tabPanelItems}
				value={tabPanelSelected}
				handleChange={handleTabSelection}
				aria-label="Clients tabs"
			/>
		</>
	);
}

export default ClientsPage;
